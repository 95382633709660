import React, { useEffect, useState, useContext } from 'react';
import { Button, Modal, Form, Row, Col, Tooltip, OverlayTrigger, Spinner } from 'react-bootstrap';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FaEdit, FaTrash, FaMinus, FaPlus, FaArrowLeft, FaCopy, FaMapPin } from 'react-icons/fa';
import { BsPinFill, BsPin } from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import APIServicenew from '../utils/APIGeneralService';
import 'react-datepicker/dist/react-datepicker.css';
import { UserContext } from '../context/UserContextProvider';
import upimg from '../imgs/default.jpg';
import { PujaImgGalleyModel } from '../components/Shared/pujaImgGalleryModal';

const AddEditAreaOfProblem = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const editDataId = searchParams.get('editDataId');
  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [eventsName, setEventsName] = useState('');
  const [errors, setErrors] = useState({});
  const [title, setTitle] = useState('');
  const [titleHi, setTitleHi] = useState('');
  const [modalFieldKey, setModalFieldKey] = useState(null);
  const [showImageGalleyModel, setShowImageGalleyModel] = useState(false);
  const [clearSel, setClearSel] = useState(false);
  const [rindex, setRindex] = useState();
  const [imageEn, setImageEn] = useState('');
  const [imageHi, setImageHi] = useState('');

  useEffect(() => {
    if (editDataId) {
      showEditData();
    }
  }, []);

  useEffect(() => {
    getEditData();
  }, [editDataId]);

  const [formData, setFormData] = useState([
    {
      appImageEn: '',
      appImageHi: '',
      target: '',
      customURL: '',
      customURLHi: '',
      eventsName: '',
    },
  ]);

  const handleReset = () => {
    setErrors({});
    setTitle('');
    setTitleHi('');
    setFormData([
      {
        appImageEn: '',
        appImageHi: '',
        target: '',
        customURL: '',
        customURLHi: '',
        eventsName: '',
      },
    ]);
  };

  const showEditData = item => {
    setSelectedId(item?._id);
    setTitle(item?.title ?? '');
    setTitleHi(item?.titleHi ?? '');

    const itemData = item?.imageInfo;
    const getData = itemData?.map(item => ({
      appImageEn: item?.image,
      appImageHi: item?.imageHi,
      target: item?.action,
      customURL: item?.url,
      customURLHi: item?.urlHi,
      eventsName: item?.page,
    }));

    setFormData(getData);
  };

  const handleAddMore = () => {
    setFormData([
      ...formData,
      {
        appImageEn: '',
        appImageHi: '',
        target: '',
        customURL: '',
        customURLHi: '',
        eventsName: '',
      },
    ]);
  };

  const handleRemove = index => {
    const updatedFormData = formData.filter((_, i) => i !== index);
    setFormData(updatedFormData);
  };

  const getEditData = async () => {
    if (editDataId) {
      let res = await APIServices.get(`areaOfProblem/findOne/${editDataId}`, headers);
      if (res?.status === 200) {
        let apiData = res?.data;
        if (apiData?.status) {
          showEditData(apiData?.data[0]);
        }
      }
    }
  };

  const handleOpenModal = (fieldKey, index) => {
    setClearSel(true);
    setModalFieldKey(fieldKey);
    setRindex(index);
    setShowImageGalleyModel(true);
  };

  const extractPaths = url => {
    const extractPath = singleUrl => {
      const parts = singleUrl.split('cloudfront.net/');
      if (parts.length > 1) {
        return parts[1];
      }
      return singleUrl;
    };

    if (Array.isArray(url)) {
      return url.map(singleUrl => extractPath(singleUrl));
    } else if (typeof url === 'string') {
      return extractPath(url);
    }
    return url;
  };

  const handleSetImageUrl = (urls, fieldKey) => {
    let extractedPath = extractPaths(urls);
    setClearSel(false);
    if (fieldKey === 'appImageEn') {
      setFormData(prevData => prevData.map((item, idx) => (idx === rindex ? { ...item, [fieldKey]: urls } : item)));

      setImageEn(extractedPath);
      setShowImageGalleyModel(false);
    }
    if (fieldKey === 'appImageHi') {
      setFormData(prevData => prevData.map((item, idx) => (idx === rindex ? { ...item, [fieldKey]: urls } : item)));
      setImageHi(extractedPath);
      setShowImageGalleyModel(false);
    }
  };

  const handleChange = (index, field, value) => {
    if (index === null) {
      // Update the title or titleHi field
      if (field === 'title') {
        setTitle(value);
        setErrors(prevErrors => ({
          ...prevErrors,
          title: value ? '' : 'Title is required',
        }));
      } else if (field === 'titleHi') {
        setTitleHi(value);
        setErrors(prevErrors => ({
          ...prevErrors,
          titleHi: value ? '' : 'Hindi Title is required',
        }));
      }
    } else {
      // setFormData(prevData => prevData.map((item, idx) => (idx === index ? { ...item, [field]: value } : item)));
      setFormData(prevData =>
        prevData.map((item, idx) =>
          idx === index
            ? {
                ...item,
                [field]: typeof value === "string" ? value : item[field], // Update only if value is a string
              }
            : item
        )
      );

      setErrors(prevErrors => {
        const updatedErrors = { ...prevErrors };
        if (!updatedErrors[index]) updatedErrors[index] = {}; // Ensure the nested object exists

        if (!value && field === 'appImageEn') {
          updatedErrors[index].appImageHi = 'Image is required';
        } else if (!value && field === 'appImageHi') {
          updatedErrors[index].appBannerImageHindi = 'Image (Hindi) is required';
        } else if (!value && field === 'target') {
          updatedErrors[index].target = 'Target is required';
        } else {
          delete updatedErrors[index][field];
        }

        return updatedErrors;
      });
    }
  };

  const validate = () => {
    const newErrors = {};

    if (!title) newErrors.title = 'Title is required';
    if (!titleHi) newErrors.titleHi = 'Hindi Title is required';

    formData.forEach((item, index) => {
      const itemErrors = {};
      if (!item.appImageEn) itemErrors.appImageEn = 'Image is required';
      if (!item.appImageHi) itemErrors.appImageHi = 'Image (Hindi) is required';
      if (!item.target) itemErrors.target = 'Target is required';

      if (item.target === 'outside') {
        if (!item.customURL) itemErrors.customURL = 'Custom URL (English) is required';
        if (!item.customURLHi) itemErrors.customURLHi = 'Custom URL (Hindi) is required';
      } else if (item.target === 'inside') {
        if (!item.eventsName) itemErrors.eventsName = 'Events Name is required';
      }

      // Assign errors for this specific item if any exist
      if (Object.keys(itemErrors).length > 0) newErrors[index] = itemErrors;
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (validate()) {
      const payload = new FormData();
      payload.append('title', title);
      payload.append('titleHi', titleHi);

      const imageInfo = formData.map(item => ({
        image: extractPaths(item.appImageEn) || '',
        imageHi: extractPaths(item.appImageHi) || '',
        action: item.target || '',
        url: item.customURL || '',
        urlHi: item.customURLHi || '',
        page: item.eventsName || '',
      }));

      payload.append('imageInfo', JSON.stringify(imageInfo));
      try {
        const response = await APIServices.post('areaOfProblem/add', payload, headers);
        if (response.data.status === true) {
          toast.success(response?.data?.message);
          setLoading(false);
          handleReset();
          Navigate('/areaOfProblem');
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        toast.error('Something went wrong!');
      } finally {
        setLoading(false); // Reset loading state after the request is completed
      }
    }
  };

  const handleUpdate = async e => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const payload = new FormData();
      payload.append('title', title);
      payload.append('titleHi', titleHi);

      const imageInfo = formData.map(item => ({
        image: extractPaths(item.appImageEn) || '',
        imageHi: extractPaths(item.appImageHi) || '',
        action: item.target || '',
        url: item.customURL || '',
        urlHi: item.customURLHi || '',
        page: item.eventsName || '',
      }));

      payload.append('imageInfo', JSON.stringify(imageInfo));
      try {
        const response = await APIServices.put(`areaOfProblem/editOne/${editDataId}`, payload, headers);
        if (response.data.status === true) {
          toast.success(response?.data?.message);
          Navigate('/areaOfProblem');
          setLoading(false);
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        toast.error('Something went wrong!');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <>
        <div className='main aarti_section'>
          <div className='gita_quote_heading'>
            <div className='container d-flex align-items-center justify-content-between'>
              <h4 className='hd-list-f left-q'>
                <FaArrowLeft
                  className='hd-cr '
                  style={{ marginRight: '15px' }}
                  size={25}
                  onClick={() => Navigate(-1)}
                />
                {selectedId ? 'Edit' : 'Add'} Problem
              </h4>
            </div>
          </div>
        </div>
      </>

      <div className='container'>
        <div className='notifiction_form p-0'>
          <Form onSubmit={e => `${editDataId ? handleUpdate(e) : handleSubmit(e)}`}>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className='mb-3 mt-3' controlId='formTitleInput'>
                  <Form.Label className='required' column sm='4'>
                    Title
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      type='text'
                      placeholder='Title...'
                      name='title'
                      className='form-control'
                      value={title}
                      isInvalid={!!errors?.title}
                      onChange={e => handleChange(null, 'title', e.target.value)}
                    />
                    <Form.Control.Feedback type='invalid'>{errors?.title}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group as={Row} className='mb-3 mt-3' controlId='formTitleHiInput'>
                  <Form.Label className='required' column sm='4'>
                    Title Hindi
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      type='text'
                      placeholder='Title...'
                      name='titleHi'
                      className='form-control'
                      value={titleHi}
                      isInvalid={!!errors?.titleHi}
                      onChange={e => handleChange(null, 'titleHi', e.target.value)}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.titleHi}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            {formData?.map((item, index) => (
              <div key={index}>
                <Row className='mt-3'>
                  <Col sm={6}>
                    <Form.Group as={Row} className='mb-3 mx-2'>
                      <Form.Label className='required' column sm='4'>
                        Image
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control
                          id='upload-photo-1'
                          name='image'
                          className='d-none'
                          isInvalid={!!errors[index]?.appImageEn}
                          onClick={e => {
                            handleOpenModal('appImageEn', index);
                            handleChange(index, 'appImageEn', e);
                          }}
                        />

                        <img
                          name='image'
                          style={{ width: '200px', height: '180px' }}
                          alt='img'
                          src={item?.appImageEn ? item?.appImageEn : upimg}
                          onClick={e => {
                            handleOpenModal('appImageEn', index);
                            handleChange(index, 'appImageEn', e);
                          }}
                        />
                        <Form.Control.Feedback type='invalid'>{errors[index]?.appImageEn}</Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group as={Row} className='mb-3 mx-2'>
                      <Form.Label className='required' column sm='4'>
                        Image Hindi
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control
                          id='upload-photo-1'
                          name='imageHi'
                          className='d-none'
                          isInvalid={!!errors[index]?.appImageHi}
                          onClick={e => {
                            handleOpenModal('appImageHi', index);
                            handleChange(index, 'appImageHi', e);
                          }}
                        />

                        <img
                          name='imageHi'
                          style={{ width: '200px', height: '180px' }}
                          alt='img'
                          src={item?.appImageHi ? item?.appImageHi : upimg}
                          onClick={e => {
                            handleOpenModal('appImageHi', index);
                            handleChange(index, 'appImageHi', e);
                          }}
                        />
                        <Form.Control.Feedback type='invalid'>{errors[index]?.appImageHi}</Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group as={Row} className='mb-3 mt-3'>
                  <Form.Label className='required' column sm='2'>
                    Target
                  </Form.Label>
                  <Col sm='10'>
                    <Form.Select
                      name='target'
                      value={item?.target}
                      onChange={e => handleChange(index, 'target', e.target.value)}
                      isInvalid={!!errors[index]?.target}
                    >
                      <option value=''>Choose</option>
                      <option value='outside'>Outside</option>
                      <option value='inside'>Inside</option>
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>{errors[index]?.target}</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                {item.target === 'outside' && (
                  <>
                    <Form.Group as={Row} className='mb-3'>
                      <Form.Label className='required' column sm='2'>
                        URL (English)
                      </Form.Label>
                      <Col sm='10'>
                        <Form.Control
                          type='text'
                          placeholder='CustomURL...'
                          name='customURL'
                          value={item.customURL}
                          onChange={e => handleChange(index, 'customURL', e.target.value)}
                          isInvalid={!!errors[index]?.customURL}
                        />
                        <Form.Control.Feedback type='invalid'>{errors[index]?.customURL}</Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='mb-3'>
                      <Form.Label className='required' column sm='2'>
                        URL (Hindi)
                      </Form.Label>
                      <Col sm='10'>
                        <Form.Control
                          type='text'
                          placeholder='CustomURL...'
                          name='customURLHi'
                          value={item.customURLHi}
                          onChange={e => handleChange(index, 'customURLHi', e.target.value)}
                          isInvalid={!!errors[index]?.customURLHi}
                        />
                        <Form.Control.Feedback type='invalid'>{errors[index]?.customURLHi}</Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </>
                )}

                {item.target === 'inside' && (
                  <Form.Group as={Row} className='mb-3' controlId={`formEventsName-${index}`}>
                    <Form.Label className='required' column sm='2'>
                      Page/Screen
                    </Form.Label>
                    <Col sm='10'>
                      <Form.Select
                        name='eventsName'
                        value={item.eventsName}
                        onChange={e => handleChange(index, 'eventsName', e.target.value)}
                        isInvalid={!!errors[index]?.eventsName}
                      >
                        <option value=''>Choose</option>
                        <option value='generate_kundli'>Generate Kundli</option>
                        <option value='panchang'>Panchang</option>
                        <option value='puja_list'>Puja List</option>
                        <option value='astrologers_list'>Astrologers List</option>
                        <option value='chadawa_list'>Chadawa List</option>
                        <option value='ask_a_question'>Ask a Question</option>
                        <option value='mandir'>Mandir</option>
                        <option value='recharge'>Recharge</option>
                        <option value='manokamna'>Manokamna</option>
                        <option value='live_darshan'>Live Darshan</option>
                        <option value='festivals_list'>Festivals List</option>
                        <option value='short_stories_list'>Short Stories List</option>
                      </Form.Select>
                      <Form.Control.Feedback type='invalid'>{errors[index]?.eventsName}</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                )}

                {formData.length > 1 && (
                 
                        <div style={{ display:"flex",color:"red",alignItems:"center",justifyContent:"end",cursor: 'pointer', }}> 
                                <Button onClick={() => handleRemove(index)} className="Dt-btn add-btn1">
                                        Remove
                                        <FaMinus
                                        className="hd-cr fw-bold"
                                        title="Remove Section"/>
                                    </Button>
                        </div>
                )}
              </div>
            ))}

            <div style={{ display:"flex",alignItems:"center",justifyContent:"end",color:"green" }}> 
             <Button onClick={handleAddMore} className="Et-btn add-btn1 btn-success1">
                Add
                <FaPlus
                  className="hd-cr"
                  title="Add Section"/>
              </Button>
              </div>

            <div className='text-center'>
                <Button type='submit' variant='success' className='mt-3 as_btn mb-5'>
                  {loading ? 'Loading...' : `${editDataId ? 'Edit' : 'Add'}`}
                </Button>
                {editDataId ? (
                  ''
                ) : (
                  <Button variant='primary' type='button' className='as_btn reset-btn' onClick={handleReset}>
                    Reset
                  </Button>
                )}
            </div>
          </Form>
        </div>
        <PujaImgGalleyModel
          id='ImgGalleyModel'
          show={showImageGalleyModel}
          onHide={() => setShowImageGalleyModel(false)}
          modalHeading={'ImgGalley'}
          size='lg'
          handleSetImageUrl={handleSetImageUrl}
          fieldKey={modalFieldKey}
          setClearSel={setClearSel}
          clearSel={clearSel}
          rindex={rindex}
        />
      </div>
    </div>
  );
};

export default AddEditAreaOfProblem;
