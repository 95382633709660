import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { Button, Modal, Form, Row, Col, Tooltip, OverlayTrigger, Spinner, Container } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash, FaMinus, FaPlus, FaCopy, FaMapPin } from 'react-icons/fa';
import { BsPinFill, BsPin } from 'react-icons/bs';
import DynamicDataTableWithCount from './DynamicDataTableWithCount';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import APIServicenew from '../utils/APIGeneralService'; 
import { formateDateAndTime,SlugifyStringWithDash, capitalizeFirstLetter, setCookies } from '../utils/Helper';
import 'react-datepicker/dist/react-datepicker.css';
import { UserContext } from '../context/UserContextProvider';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import usePermissionData from '../components/Customhook/custompermission';
import Notauthorized from '../components/Notauthorized';
import Papa from 'papaparse';


const AreaOfProblem = () => {
  
    const navigate = useNavigate();
    document.title = 'Area Of Problem';
    const permissioncontext =usePermissionData("areaOfProblem")
    const cookies = new Cookies();
    let token = cookies.get('accessToken');
    const { refreshToken } = useContext(UserContext);
    const { user: adminUser } = useContext(UserContext);
  
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [allDataCount, setAllDataCount] = useState();
    const [flag, setFlag] = useState(false);
    const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
    const headers = { Authorization: `Bearer ${token}` };
    const [name, setName] = useState('');
    const [nameHi, setNameHi] = useState('');
    const [status, setStatus] = useState('');
  
    const columns = useMemo(() => [
      {
        Header: 'NO.',
        Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
      },
      {
        Header: 'Name',
        Cell: e => {
          return  <span>{e.row.original?.title ?? '_'}</span>
        },
      },
      {
        Header: 'Name(Hindi)',
        Cell: e => {
          return  <span>{e.row.original?.titleHi ?? '_'}</span>
        },
      },
     
      {
        Header: 'Status',
        Cell: e => {
          if (e.row.original.status === 1) {
            return (
              <h5>
                <input type='button'  className='_active' value='Active' onClick={() => handleStatusUpdate(e.row.original)} />
              </h5>
            );
          } else {
            return (
              <h5>
                <input type='button'  className='_inactive' value='Inactive' onClick={() => handleStatusUpdate(e.row.original)} />
              </h5>
            );
          }
        },
      },
      
      {
        Header: 'Created At',
        Cell: e => {
          return (
            <>
              <span>{e.row.original.createdAt ? moment(e.row.original.createdAt).format('DD MMM, YYYY hh:mm A') : '_'}</span>
            </>
          );
        },
      },
      {
        Header: 'Updated At',
        Cell: e => {
          return (
            <>
              <span>{e.row.original.updatedAt ? moment(e.row.original.updatedAt).format('DD MMM, YYYY hh:mm A') : '_'}</span>
            </>
          );
        },
      },
      {
        Header: 'Action',
        Cell: e => {
          if (e.row.original._id) {
            
            return (
              <>
              <div className='d-flex'>
                  <Button type="button" className='Et-btn edit-btn1' onClick={()=>{getEditaData(e.row.original)}}>Edit</Button>
                  <Button type="button" className='ms-2 Dt-btn' onClick={() => { handleShowDt(e.row.original)}}>Delete</Button>
              </div>
              </>
            );
          }
        },
      },
     
    ]);
  
    const [showdt,setShowdt ] = useState(false)
    const [selectedId, setSelectedId] = useState("")
  
    const handleCloseDt = () => setShowdt(false);
    const handleShowDt = id => {
      setSelectedId(id?._id);
      setShowdt(true);
    };
  
    const logout = () => {
      refreshToken(null);
      setCookies('accessToken', null);
      navigate('/');
    };
  
    const getEditaData = async (data) => {
      navigate(`/addEditAreaOfProblem?editDataId=${data?._id}`);
    };
  
    const handleDelete = async () => {
      const response = await APIServices.delete(`areaOfProblem/findByIdAndremove/${selectedId}`, headers);
      if (response?.data?.status === true) {
        let newData = tableData.filter(item => item._id !== selectedId);
        setTableData(newData);
        toast.success(response?.data.message);
        setShowdt(false);
        handleCloseDt()
        areaOfProblemList()
      } else {
        console.log("Something went Wrong")
      }
    };
  
    const handleReset = () => {
      setName('')
      setNameHi('')
      setStatus('')
      setFlag(true)
    };
  
   
    useEffect(() => {
        areaOfProblemList(skip, limit, pageIndex);
    }, [flag]);
  
    const areaOfProblemList = useCallback(
      async (skip, limit, index) => {
        try {
          const response = await APIServices.get(`areaOfProblem/getAll/${skip}/${limit}?title=${name}&titleHi=${nameHi}&status=${status}`,headers);
          if (response?.data?.status === true) {
            setPageCount(Math.ceil(response.data?.totalCount/limit));
            setAllDataCount(response.data?.totalCount)
            setTableData(response.data?.Listdata);
            setFlag(false)
            window.scrollTo({ top: 0 });
          }else{
            if(response?.response?.data?.status == 4){
              logout()
             }
          }
        } catch (e) {
          throw new Error(`API error:${e?.message}`);
        }
        setSkip(skip);
        setLimit(limit);
        setPageIndex(index);
      },
      [pageIndex, skip, name,nameHi,status]
    );
  
    const handleStatusUpdate = async item => {
      const formData = new FormData();
      var datas = item?.status === 1 ? 2 : 1;
      let id = item?._id;
      formData.append('status', datas);
  
     const res = await APIServices.put(`areaOfProblem/updateStatus/${id}`, formData, headers)
     if(res?.data?.status === true){
        areaOfProblemList(skip, limit, pageIndex);
     }
     else{
       console.log("error..")
     }
    };
  
    const handleFilterClick = () => {
      if (name || nameHi || status ) {
        areaOfProblemList(skip, limit, 0, name,nameHi,status);
      }
    };
  
  return (
    <div className='main aarti_section'>
        <>
          <div className='gita_quote_heading'>
            <div className='container d-flex align-items-center justify-content-between'>
              <h4 className='hd-list-f left-q'>Area of List <small>({allDataCount})</small></h4>
              <div className='_btn'>
                <Button
                  style={{ backgroundColor: 'green' }}
                  className='by-btn as_btn me-0'
                  variant='primary'
                //   disabled={!permissioncontext.add}
                  onClick={() => {
                    navigate('/addEditAreaOfProblem');
                  }}
                >
                  <FaPlus style={{ marginRight: '5px' }} size={13} />
                    Add
                </Button>
              </div>
            </div>
          </div>
        </>
          <div className='user-list-table'>
            <div className='feed_area box-shadow br-6 p-20 my-3 font_16'>
              <div className='chat_search_box user-search'>
                <div className='chat-search-field'>
                    <Form>
                        <Form.Group as={Row} className='' controlId=''>
                          <Col md={3} className='mb-3'>
                            <Form.Label className='label-f-w-cd'>Search by Name</Form.Label>
                            <Form.Control
                              className=' mt-2 form-ch'
                              name='name'
                              type='search'
                              placeholder='Search By Name'
                              value={name}
                              onChange={e => {
                                setName(e.target.value);
                                setSkip(0);
                              }}
                            />
                          </Col>
                          <Col md={3} className='mb-3'>
                            <Form.Label className='label-f-w-cd'>Search by Hindi Name</Form.Label>
                            <Form.Control
                              className=' mt-2 form-ch'
                              name='nameHi'
                              type='search'
                              placeholder='Search By Hindi Name'
                              value={nameHi}
                              onChange={e => {
                                setNameHi(e.target.value);
                                setSkip(0);
                              }}
                            />
                          </Col>
                          <Col md={3} className='mb-3'>
                          <Form.Label className='label-f-w-cd'>Status</Form.Label>
                            <Form.Select
                              aria-label='categories'
                              className='astroWatch-select-hover hd-cr form-ch mt-2'
                              value={status}
                              onChange={e => {
                                setStatus(e.target.value);
                                setSkip(0);
                              }}
                            >
                              <option>Select Status</option>
                              <option value='1'>Active</option>
                              <option value='2'>Inactive</option>
                            </Form.Select>
                        </Col>
                          
                        <Col md={3} className='mt-3'>
                            <Button
                              variant='secondary'
                              className='as_btn ms-0'
                              onClick={() => {
                                handleFilterClick();
                                setSkip(0);
                              }}
                            >
                              Filter
                            </Button>
                            <Button
                              variant='secondary'
                              className='as_btn reset-btn'
                              onClick={() => {
                                handleReset();
                              }}
                            >
                              Reset
                            </Button>
                            
                          </Col>
                          
                        </Form.Group>

                    </Form>
                </div>
              </div>
            </div>
          </div>
              {tableData && tableData.length > 0 ? (
              <DynamicDataTableWithCount
                columns={columns}
                data={tableData}
                totalCount={pageCount}
                initialState={{
                  pageIndex: pageIndex,
                  pageSize: limit,
                }}
                fetchData={areaOfProblemList}
                forcePage={skip / limit}
              />
            ) : (
              <h2 className='no_records'>Records not Available</h2>
            )}

      <Modal
          centered
          show={showdt}
          size='sm'
          aria-labelledby='example-modal-sizes-title-sm'
          backdrop='static'
          onHide={handleCloseDt}
        >
          <Modal.Header className='modal-header-hd'>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' className='cancle_btn' onClick={handleCloseDt}>
              Cancel
            </Button>
            <Button variant='primary' className='success_btn' onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
  )
}

export default AreaOfProblem